$('.index-slider').each(function(){
	var g = $(this),
		items = g.find('.swiper-slide');

    items.each(function () {
        if ($(document).width() <= 1025) {
            $(this).find('a').css('background-image', 'url("' + $(this).find('a').data('mobile-image') + '")');
        } else {
            $(this).find('a').css('background-image', 'url("' + $(this).find('a').data('image') + '")');
        }
    });

	if (items.length > 1) {
		g.append('<div class="slider__controls slider__controls_vertical"><div class="slider__controls-nav _prev"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 13.4 22.6" fill="#b40c1e"><path d="M11.3 13.4l-9.2 9.2L0 20.5l9.2-9.2L0 2.1 2.1 0l11.3 11.3-2.1 2.1z"/></svg></div><div class="slider__controls-pagination"></div><div class="slider__controls-nav _next"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 13.4 22.6" fill="#b40c1e"><path d="M11.3 13.4l-9.2 9.2L0 20.5l9.2-9.2L0 2.1 2.1 0l11.3 11.3-2.1 2.1z"/></svg></div></div>');

        var autoplayTimer = (g.data('autoplay')) ? Number(g.data('autoplay')) : 0;
        if (autoplayTimer > 0) {
            autoplayTimer = { delay: autoplayTimer };
        }
		
		var swiper = new Swiper(g.find('.swiper-container')[0],{
			loop: true,
			speed: 400,
			autoHeight: true,
			autoplay: autoplayTimer,
			autoplayDisableOnInteraction: true,
			navigation: {
				prevEl: g.find('.slider__controls-nav._prev')[0],
				nextEl: g.find('.slider__controls-nav._next')[0],
				disabledClass: '_disabled',
				hiddenClass: '_hidden'
			},
			pagination: false
		});
	};
});


$('.gallery-block').each(function () {
	var subItems = $(this).find('gallery-block__item a img');
	if ($(document).width() <= 1025) {
		subItems.css('background-image', 'url("' + subItems.data('mobile-image') + '")');
	} else {
		subItems.css('background-image', 'url("' + subItems.data('image') + '")');
	}
})

$('.gallery-block__item a img').each(function () {
	var item = $(this);
	if ($(document).width() <= 1025) {
		item.prop('src', item.data('mobile-image'));
	} else {
		item.prop('src', item.data('image'));
	}
})


$('.appliances').each(function(){
	var ti = $(this),
		items = ti.find('.appliances__item');

	items.each(function(){
		var item = $(this);
		var text = item.find('.appliances__item-heading').text();
		item.append('<div class="appliances__item-heading-hover">'+ text +'</div>');
	});

	if (items.length > 1) {
		ti.find('.swiper-container').append('<div class="swiper-scrollbar"></div>');

		var swiper = new Swiper(ti.find('.swiper-container')[0],{
			speed: 400,
			slidesPerView: 'auto',
			spaceBetween: 20,
			scrollbar: {
				el: ti.find('.swiper-scrollbar')[0],
				draggable: true,
				snapOnRelease: false
			},
			breakpoints: {
				600: {
					scrollbar: {
						dragSize: 100
					}
				}
			}
		});
	};
});

$('.index-sales').each(function(){
	var g = $(this),
		items = g.find('.swiper-slide');

	if (items.length > 1) {
		g.append('<div class="index-sales__controls"><div class="w"><div class="slider__controls"><div class="slider__controls-nav _prev"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 13.4 22.6" fill="#b40c1e"><path d="M11.3 13.4l-9.2 9.2L0 20.5l9.2-9.2L0 2.1 2.1 0l11.3 11.3-2.1 2.1z"/></svg></div><div class="slider__controls-pagination"><span class="slider__controls-pagination-item"></span></div><div class="slider__controls-nav _next"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 13.4 22.6" fill="#b40c1e"><path d="M11.3 13.4l-9.2 9.2L0 20.5l9.2-9.2L0 2.1 2.1 0l11.3 11.3-2.1 2.1z"/></svg></div></div></div></div>');

	    var autoplayTimer = (g.data('autoplay')) ? Number(g.data('autoplay')) : 0;
	    if (autoplayTimer > 0) {
	        autoplayTimer = { delay: autoplayTimer };
	    }
		
		var swiper = new Swiper(g.find('.swiper-container')[0],{
			loop: true,
			speed: 400,
			autoHeight: true,
			autoplay: autoplayTimer,
			autoplayDisableOnInteraction: true,
			parallax: true,
			navigation: {
				prevEl: g.find('.slider__controls-nav._prev')[0],
				nextEl: g.find('.slider__controls-nav._next')[0],
				disabledClass: '_disabled',
				hiddenClass: '_hidden'
			},
			pagination: {
				el: g.find('.slider__controls-pagination')[0],
				clickable: true,
				bulletClass: 'slider__controls-pagination-item',
				bulletActiveClass: '_active'
			}
		});
	};
});