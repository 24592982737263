$('.product-package').each(function(){
	var pp = $(this),
		items = pp.find('.product-package__item'),
		infos = pp.find('.product-package__info-item');

	if (items.length > 2) {
		var swiper = new Swiper(pp.find('.swiper-container')[0],{
			speed: 400,
			slidesPerView: 'auto',
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
			    400: {
			      slidesPerGroup: 1,
			      slidesPerView: 1,
			      spaceBetween: 10
			    },
			    700: {
			      slidesPerGroup: 2,
			      slidesPerView: 2,
			      spaceBetween: 10
			    },
			    1200: {
			      spaceBetween: 10
			    }
			  },
		});
	};

	items.on('click',function(){
		var item = $(this)
		if (!item.hasClass('_active')) {
			items.removeClass('_active');
			item.addClass('_active');
			var index = items.index(item);

			infos.hide().eq(index).show();
			if (items.length > 2) swiper.slideTo(index);

            if (infos.eq(index).find('.swiper-container').length > 1 && infos.eq(index).find('.swiper-container')[0].swiper) {
				infos.eq(index).find('.swiper-container')[0].swiper.update();
			};
		};
	});

	items.eq(0).trigger('click');
});