$('.catalogue__item').each(function(){
	var item = $(this);


	function show() {
		var p = item.find('.catalogue__popup');
		if (!p.hasClass('_ready')) {
			p.wrap('<div class="popup-content"></div>');
			p.prepend('<a href="'+item.find('.catalogue__item-image').parent().attr('href')+'"><div class="catalogue__popup-image">'+item.find('.catalogue__item-image').html()+'</div></a>');
			p.find('.catalogue__popup-body').prepend('<div class="catalogue__popup-model">'+item.find('.catalogue__item-model').text()+'</div>');
			p.find('.catalogue__popup-body').prepend('<div class="catalogue__popup-heading"><a href="'+item.find('.catalogue__item-image').parent().attr('href')+'">'+item.find('.catalogue__item-heading').text()+'</a></div>');

			p.find('._js-catalogue__popup-continue').on('click',function(){
				popup.trigger('hide');
			});

			p.addClass('_ready');
		};
		
		popup.trigger('show',{
			content: p
		});
	};
	item.find('._js-catalogue__buy').on('click',function(){
		show();
		return false;
	});

	// $('.catalogue__list-item:eq(0)').find('._js-catalogue__buy').trigger('click');
});