$('.side-nav').each(function(){
	var sideBar = $('.side-nav').eq(0),
		burger = $('.header-burger').eq(0),
		sidebarBack = $('.side-nav-back').eq(0);
		

	burger.click(function(){
		if (!$('html').hasClass('_side-nav_visible')) {
			show();
		} else {
			hide();
		};
	});
	sidebarBack.click(hide);

	var timer;
	function show() {
		clearTimeout(timer);
		W.trigger('content:fix');
		sideBar.show();
		sidebarBack.show();
		setTimeout((function(){
			$('html').addClass('_side-nav_visible');
		}),50);
	};
	function hide() {
		W.trigger('content:unfix');
		$('html').removeClass('_side-nav_visible');
		timer = setTimeout((function(){
			sideBar.hide();
			sidebarBack.hide();
		}),200);
	};
	sideBar
		.on('show',show)
		.on('hide',hide);


	sideBar.find('.side-nav__sub-list').each(function(){
		var list = $(this);
		var item = list.parent().find('.side-nav__link');

		item.on('click',function(){
			list.slideToggle(200);
			return false;
		});
	});



	var nav = sideBar.find('.side-nav__nav');
	nav.find('.side-nav__link').each(function(){
		var a = $(this);
		if (a.next('.side-nav__list').length) {
			a.addClass('side-nav__link_handler').append(ard);
		};
	});
	var navIsAnimated = false;
	nav.on('click','.side-nav__link_handler',function(){
		if (!navIsAnimated) {
			navIsAnimated = true;
			var a = $(this);
			var newNav = $('<div class="side-nav__nav-item _new"><div class="side-nav__nav-back">go back'+ ard +'</div></div>');
			newNav.append(a.next('.side-nav__list').clone());
			newNav.appendTo(nav);
			nav.height(nav.height()).transit({
				x: -nav.width(),
				height: newNav.height()
			},300,function(){
				nav.css({
					transform: '',
					height: ''
				});
				nav.find('.side-nav__nav-item:not(._new)').hide();
				newNav.removeClass('_new');
				navIsAnimated = false;
			});
		};
		return false;
	});
	nav.on('click','.side-nav__nav-back',function(){
		if (!navIsAnimated) {
			navIsAnimated = true;
			var items = nav.find('.side-nav__nav-item');
			var prevNav = items.eq(items.length - 2).addClass('_prev').show();
			nav.height(nav.height()).transit({
				x: nav.width(),
				height: prevNav.height()
			},300,function(){
				nav.css({
					transform: '',
					height: ''
				});
				nav.find('.side-nav__nav-item:last').remove();
				prevNav.removeClass('_prev');
				navIsAnimated = false;
			});
		};
	});

	sideBar.find('.side-nav-top__select').on('change',function(){
		var s = $(this);
		var uri = s.find('option:selected').data('href');
		window.location.href = uri;
	});

	sideBar.find('.side-nav__extra-sub-link').on('click',function(){
		var a = $(this),
			text = a.text(),
			extraNav = sideBar.find('.side-nav__extra');

		var back = $('<p class="side-nav__extra-go-back"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10"><path d="M15.4.5c-.8-.7-2.1-.7-2.8 0L7.9 4.8 3.3.5C2.5-.1 1.2 0 .5.7c-.6.7-.6 1.7 0 2.4L7.9 10l7.5-6.9c.7-.6.8-1.7.1-2.5l-.1-.1z"></path></svg> '+ text +'</span></p>');

		var subNav = a.next('ul').clone().addClass('side-nav__extra');

		extraNav.hide();
		extraNav.before(back);
		extraNav.after(subNav);

		back.on('click',function(){
			subNav.remove();
			back.remove();
			extraNav.show();
			return false;
		});
		return false;
	});
});