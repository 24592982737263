$('.product__gallery').each(function(){
	var g = $(this),
		items = g.find('.product__gallery-item'),
		thumbs = g.find('.product__gallery-thumbs-item'),
		thumbsWrapper = g.find('.product__gallery-thumbs');

	if (items.length > 1) {
		g.append('<div class="slider__controls"><div class="slider__controls-pagination"></div></div>');

		if (items.length > 5) {
			thumbsWrapper.append('<div class="slider__controls-nav _prev noselect"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 13.4 22.6" fill="#b40c1e"><path d="M11.3 13.4l-9.2 9.2L0 20.5l9.2-9.2L0 2.1 2.1 0l11.3 11.3-2.1 2.1z"/></svg></div><div class="slider__controls-nav _next noselect"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 13.4 22.6" fill="#b40c1e"><path d="M11.3 13.4l-9.2 9.2L0 20.5l9.2-9.2L0 2.1 2.1 0l11.3 11.3-2.1 2.1z"/></svg></div></div>');
		};

		var thumbsSwiper = new Swiper(thumbsWrapper.find('.swiper-container')[0],{
			direction: 'vertical',
			spaceBetween: 2,
			slidesPerView: 'auto',
			speed: 400,
			autoHeight: true,
			navigation: {
				prevEl: thumbsWrapper.find('.slider__controls-nav._prev')[0],
				nextEl: thumbsWrapper.find('.slider__controls-nav._next')[0],
				disabledClass: '_disabled',
				hiddenClass: '_hidden'
			},
			on: {
				slideChange: function(){
					swiper.slideTo(this.activeIndex);
				}
			}
		});
		
		var swiper = new Swiper(g.find('.swiper-container')[0],{
			// loop: true,
			speed: 400,
			autoHeight: true,
			autoplayDisableOnInteraction: true,
			pagination: {
				el: g.find('.slider__controls-pagination')[0],
				clickable: true,
				bulletClass: 'slider__controls-pagination-item',
				bulletActiveClass: '_active'
			},
			on: {
				slideChange: function(){
					thumbs.removeClass('_active');
					thumbs.eq(this.realIndex).addClass('_active');
					thumbsSwiper.slideTo(this.activeIndex);
				}
			}
		});
		thumbs.on('click',function(){
			var a = $(this);
			if (!a.hasClass('_active')) {
				var index = thumbs.index(this);
				swiper.slideTo(index);
			};

			return false;
		});
	} else {
		thumbsWrapper.remove();
	};
});

$('.-js-more').click(function(){
	$(this).next('div').slideToggle();
	$(this).hide();
});


$('.product__info').each(function(){
	var p = $(this);
	var total = p.find('._js-product-total');

	var items = p.find('*[data-price]');

	function calculate() {
		var price = 0;
		items.each(function(){
			var item = $(this);
			if (item.is('input')) {
				if (item.is('input[type="checkbox"]:checked')) {
					price += $(this).data('price');
				} else if (item.is('input[type="hidden"]') && item.val() == 'true') {
					price += $(this).data('price');
				};
			} else {
				price += $(this).data('price');
			};
		});

		total.text(price);
	};
	calculate();

	p.find('input[type="checkbox"][data-price]').on('click',function(){
		calculate();
	});

	p.find('.product__radios').each(function(){
		var r = $(this);
		var radios = r.find('.product__radios-cta');
		radios.on('click',function(){
			var a = $(this);
			if (!a.hasClass('_active')) {
				radios.filter('._active').trigger('click');
				a.addClass('_active');
				a.find('input').val('true');
			} else {
				a.removeClass('_active');
				a.find('input').val('false');
			};
			calculate();
		});
	});
});

$('.js-schedule-appointment').click(function () {
    $(this).parents('.accordeon__body').find('.locations__item-iframe').slideToggle();
});