var TGNS = (function(){
	var wrapper = $('<div class="tgns__wrapper"></div>').appendTo('body');
	function show(data) {
		var opts = {
			title: '',
			time: 3,
			speed: 0.3
		};
		if (data) {
			opts = $.extend(opts,data);
		};
		opts.time *= 1000; // seconds to ms
		opts.speed *= 1000;
		// console.log(opts);

		wrapper.show();
		var item = $('<div class="tgns__item"><div class="tgns__item-inner">'+opts.title+'</div></div>').appendTo(wrapper);
		item.css({
			opacity: 0,
			y: 10
		}).show().transit({
			opacity: 1,
			y: 0
		},opts.speed);

		item.on('destroy',function(){
			clearTimeout(item.data('timer'));
			item.css({
				height: item.height()
			}).transit({
				opacity: 0,
				y: -10,
				height: 0
			},opts.speed,function(){
				item.remove();
				if (wrapper.is(':empty')) {
					wrapper.hide();
				};
			});
		});
		item.data('timer',setTimeout(function(){
			item.trigger('destroy');
		},opts.time));

		item.on('click',function(){
			item.trigger('destroy');
		});
	};
	return {
		show: show
	};
})();