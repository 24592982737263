var Slider = (function () {
    var updateSlides = function (nextActiveSlide) {
        $('.slide').removeClass('prev-1');
        $('.slide').removeClass('next-1');
        $('.slide').removeClass('prev-2');
        $('.slide').removeClass('active');
        $('.slide').removeClass('next-2');
        $('.slide').removeClass('prev-3');
        $('.slide').removeClass('next-3');

        nextActiveSlide.addClass('active');

        nextActiveSlide.prev().addClass('prev-3');
        nextActiveSlide.prev().prev().addClass('prev-2');
        nextActiveSlide.prev().prev().prev().addClass('prev-1');
        nextActiveSlide.addClass('active');
        nextActiveSlide.next().addClass('next-1');
        nextActiveSlide.next().next().addClass('next-2');
        nextActiveSlide.next().next().next().addClass('next-3');
    };

    var init = function () {
        var slider = document.getElementById('slider');
        if (!slider) {
            return;
        }
        var dir = $('html').attr('dir');
        var swipeHandler = new Hammer(slider);
        swipeHandler.get('swipe').set({ threshold: 1, velocity: 0.1 });
        swipeHandler.on('swipeleft', function (e) {
            if (dir == 'rtl') $('.arrow-left').trigger('click');
            else $('.arrow-right').trigger('click');
        });

        swipeHandler.on('swiperight', function (e) {
            if (dir == 'rtl') $('.arrow-right').trigger('click');
            else $('.arrow-left').trigger('click');
        });

        $('.arrow-right , .arrow-left').click(function (event) {
            var nextActiveSlide = $('.slide.active').next();

            if ($(this).hasClass('arrow-left')) nextActiveSlide = $('.slide.active').prev();

            if (nextActiveSlide.length > 0) {
                var nextActiveIndex = nextActiveSlide.index();
                $('.dots span').removeClass('active');
                $($('.dots').children()[nextActiveIndex]).addClass('active');

                updateSlides(nextActiveSlide);
            }
        });

        $('.dots span').click(function (event) {
            var slideIndex = $(this).index();
            var nextActiveSlide = $($('.slider').children()[slideIndex]);
            $('.dots span').removeClass('active');
            $(this).addClass('active');

            updateSlides(nextActiveSlide);
        });

        var updateToNextSlide = function (nextActiveSlide) {};
    };
    return {
        init: init,
        updateSlides: updateSlides,
    };
})();

$(function () {
    Slider.init();
});

function fun1() {
    var rng = document.getElementById('r1'); //rng - это Input
    var letter = +rng.value;
    var array = $('.slider')
        .children()
        .map(function () {
            return +$(this).data('letter');
        })
        .toArray();
    var searchFunc = function (x) {
        return x === letter;
    };
    var indexFirst = array.findIndex(searchFunc);
    if (indexFirst === -1) {
        return;
    }
    var activeSlide = $($('.slider').children()[indexFirst]);
    Slider.updateSlides(activeSlide);

    var p = document.getElementById('one'); // p - абзац
    p.innerHTML = indexMiddle;

    $('.brands-blocks__item').hide();
    $('.brands-blocks__item[data-letter="' + letter + '"]').show();
}

$('.brands-letter').click(function () {
    var letter = $(this).data('letter');
    $('#r1').val(letter);
    fun1();
});
