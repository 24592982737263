$('.blog__slider').each(function(){
	var g = $(this),
		items = g.find('.swiper-slide');

	if (items.length > 1) {
		g.append('<div class="slider__controls slider__controls_vertical"><div class="slider__controls-nav _prev"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 13.4 22.6" fill="#b40c1e"><path d="M11.3 13.4l-9.2 9.2L0 20.5l9.2-9.2L0 2.1 2.1 0l11.3 11.3-2.1 2.1z"/></svg></div><div class="slider__controls-pagination"></div><div class="slider__controls-nav _next"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 13.4 22.6" fill="#b40c1e"><path d="M11.3 13.4l-9.2 9.2L0 20.5l9.2-9.2L0 2.1 2.1 0l11.3 11.3-2.1 2.1z"/></svg></div></div>');

	    var autoplayTimer = (g.data('autoplay')) ? Number(g.data('autoplay')) : 0;
	    if (autoplayTimer > 0) {
	        autoplayTimer = { delay: autoplayTimer };
	    }
		
		var swiper = new Swiper(g.find('.swiper-container')[0],{
			loop: true,
			speed: 400,
			autoHeight: true,
			autoplay: autoplayTimer,
			autoplayDisableOnInteraction: true,
			navigation: {
				prevEl: g.find('.slider__controls-nav._prev')[0],
				nextEl: g.find('.slider__controls-nav._next')[0],
				disabledClass: '_disabled',
				hiddenClass: '_hidden'
			},
			pagination: {
				el: g.find('.slider__controls-pagination')[0],
				clickable: true,
				bulletClass: 'slider__controls-pagination-item',
				bulletActiveClass: '_active'
			}
		});
	};
});