$('.header__nav-top').each(function(){
	var nav = $(this),
		items = $(),
		items2 = $(),
		nav2 = $('.header__nav2'),
		nav3 = $('.header__nav3'),
		wrapper = $('.header__nav-wrapper'),
		topnav = $('.header__nav');

	nav.find('.header__nav-top-item').each(function(){
		var item = $(this);
		if (item.find('>ul').length) {
			item.data('nav', true);
			items = items.add(item);
		} else {
			items2 = items2.add(item);
		}
	});
	$('.header__nav-side-item').each(function(){
		var item = $(this);
		if (item.find('>ul').length) {
			item.data('nav', true);
		};
	});

	// second level nav always active when the link has _active class
	// catalogue-cat-1.html
	var isTopActive = false;
	var topActiveNav;
	nav.find('.header__nav-top-link._active').each(function(){
		if ($(this).next('ul').length) {
			topActiveNav = $(this).next('ul');
			nav2.append(topActiveNav.clone());
			nav2.show();
			isTopActive = true;
			topnav.addClass('_submenu-active');
			$('.header').addClass('_submenu-active');
		};
	});

	// second level sidenav always active when the link has _active class
	// about-our-story.html
	var isSideActive = false;
	var sideActiveNav;

	topnav.find('.header__nav-side-item ul').each(function(){
		var item = $(this).parent();
		var itemOffset = (W.width() - (item.offset().left + item.width()/2)) * 2;
		var ul = $(this);
		nav3.css({
			visibility: 'hidden',
			display: 'block',
			textAlign: 'center'
		}).append(ul);
		ul.css({
			display: 'inline-block'
		});
		var ulWidth = ul.width();
		var ulOffset = ul.offset().left;
		var ulPL = W.width() - itemOffset;
		ulPL = (topnav.outerWidth() - ulPL < ulWidth) ? 0 : ulPL;
		ul.css({
			paddingLeft: ulPL - 20
		});

		nav3.css({
			visibility: '',
			display: '',
			textAlign: ''
		});

		ul.css({
			display: ''
		}).appendTo(item);
	});

	topnav.find('.header__nav-side-link._active').each(function(){
		if ($(this).next('ul').length) {
			sideActiveNav = $(this).next('ul');
			nav3.append(sideActiveNav.clone());
			//nav3.show();
		    // TG-732 Side items sub-menus should not be shown by default
			isSideActive = false;
			wrapper.addClass('_side-nav');
		};
	});

	var hideTimer;
	var isNav2 = false;
	var isNav3 = false;
	function hideNav(timeout) {
		if (!timeout) 
		{
			timeout = 1500;
		}
		clearTimeout(hideTimer);
		hideTimer = setTimeout(function(){
			if (!isNav3) {
				if (!isSideActive) {
					nav3.hide();
					wrapper.removeClass('_side-nav');
				} else {
					if (isNav2) {

					} else {
						nav3.empty().append(sideActiveNav.clone());
						wrapper.addClass('_side-nav');
					}
				};

				if (!isNav2) {
					if (!isTopActive) {
						nav2.hide();
						topnav.removeClass('_active');
					} else {
						nav2.empty().append(topActiveNav.clone());
						topnav.removeClass('_active');
					};
				};
			};
		}, timeout);	
	};
	items.each(function(){
		var item = $(this);
		item.on('mouseenter',function(){
			wrapper.removeClass('_side-nav');
			nav2.empty().append(item.find('>ul').clone());
            nav2.show();
		    nav3.hide().empty();
			if (!isTopActive) topnav.addClass('_active');
			isNav2 = true;
			if (isSideActive) {
				nav3.empty();
				wrapper.removeClass('_side-nav');
			};
		}).on('mouseleave',function(){
			isNav2 = false;
			hideNav();
		});
	});
	items2.on('mouseenter',function(){
		isNav2 = false;
		hideNav(0);
	});
	nav2.on('mouseenter',function(){
		wrapper.removeClass('_side-nav');
		isNav2 = true;
	}).on('mouseleave',function(){
		isNav2 = false;
		hideNav();
	});

	nav3.on('mouseenter',function(){
		isNav3 = true;
	}).on('mouseleave',function(){
		isNav3 = false;

		hideNav();
	});

	nav2.on('mouseenter','li',function(e){
        var item = $(this);
	    topnav.addClass('_active');
		if (item.find('>ul').length) {
			nav3.empty().append(item.find('>ul').clone());
			nav3.show();
			isNav3 = true;
		} else {
		    nav3.hide().empty();
		}
	}).on('mouseleave','li',function(){
		isNav3 = false;
		hideNav();
	});

	$('.header__nav-side-item').each(function(){
		var item = $(this);
		if (item.data('nav')) {
			item.on('mouseenter',function(){
				wrapper.addClass('_side-nav');
				nav3.empty().append(item.find('>ul').clone());
				nav3.show();
				isNav3 = true;
			}).on('mouseleave',function(){
				isNav3 = false;
				hideNav();
			});
		};
	});
});


$('.mc_embed_signup input[type="submit"]').click(function(e) {
    e.preventDefault();
    var form = $(this).closest("form");
    if ($(form).valid()) {
        $(form).submit();
    }
});

$('.mc_embed_signup form').submit(function(e) {
	e.preventDefault();
	var url = $(this).attr('action');
	$.post({
		url: url,
		data: $(this).serialize(),
		success: function (answer) {
            console.log(answer);
            if (answer.result == 'error') {
                $('#mce-error-response').show().html(answer.msg);
            } else {
                $('#mce-success-response').show();
                $('.mce-success-response').show();
                $('.mc-field-group_form').hide();
            }
		}
	});
});


$('.footer__newsletter button[type="submit"]').click(function (e) {
    e.preventDefault();
    var form = $(this).closest("form");
    if ($(form).valid()) {
        $(form).submit();
    }
});

$('.footer__newsletter form').submit(function (e) {
    e.preventDefault();
    $(".footer__newsletter-error-text").empty();
    var url = $(this).attr('action');
    $.post({
        url: url,
        data: $(this).serialize(),
        success: function (answer) {
            if (answer.result == 'error') {
                $(".footer__newsletter-error-text").html(answer.msg);
            } else {
                var popupContent = $('.footer__newsletter .newsletter-popup');
                popup.trigger('show', [{ content: popupContent }]);
            }
        }
    });
});

$('.footer__newsletter-input').keyup(function () {
    $(".footer__newsletter-error-text").empty();
});

function trackShow() {
	$("#delivery-truck-status").show();
	$('.delivery_loading').hide();
}

function trackOrder() {
    if (!$('.delivery_form').valid()) {
        return false;
    }
    var val = $('.delivery_input').val();        

    $("#delivery-truck-status iframe").attr("src", 'https://tgappliance.dispatchtrack.com/track_order/a4ef2fc/' + val);
	iFrameResize({ log: true, scrolling: true }, '#deliveryIframe');
    $('.delivery_form').hide();
    $('.delivery_loading').show();
    setTimeout(trackShow, 2000);
}

$(function() {
    if (typeof fbq == 'function') {
        fbq('track', 'ViewContent', {
            content_name: 'Delivery Tracking',
            content_category: 'Landing Page'
        });
    }

    $('.delivery_submit').click(function() {
        trackOrder();
    });

    $('#idDeliveryInput').keydown(function(event) {
        if (event.keyCode === 13) {
            trackOrder();
        }
    });
    $('.search-again').click(function (e) {
        e.stopPropagation();
        $("#delivery-truck-status").hide();
        $('.delivery_form').show();
    });
});


var obj, leftPosition;

$(window).resize(function(){
	if ($('.cart__body').height() > $('.cart__descr').height()) {
		obj = $('.cart__descr');
		leftPosition = $('.cart__info').offset().left + $('.cart__info').width() - 392;
	} else {
		obj = false;
	}
	$(window).scroll();
});
$(window).resize();

$(window).scroll(function() {
	if (!obj) {
		return;
	}
	var scrollTop = $(window).scrollTop();
	var topOffset = $('.cart__info').offset().top;
	var bottomOffset = $('.cart__body').offset().top + $('.cart__body').height() - obj.height();
	if (scrollTop >= bottomOffset) {
		obj.removeClass('-fixed').addClass('-bottom').css({top: 'auto', left: 'auto'});
	} else if (scrollTop > topOffset) {
		obj.removeClass('-bottom').addClass('-fixed').css({top: '0px', left: leftPosition});
	} else {
		obj.removeClass('-fixed -bottom').css({top: 'auto', left: 'auto'});;
	}
});