$('.product__related-slider').each(function(){
	var g = $(this),
		items = g.find('.swiper-slide');

	if (items.length > 1) {
		g.append('<div class="product__related-slider-nav _prev"></div><div class="product__related-slider-nav _next"></div><div class="slider__controls"><div class="slider__controls-pagination"></div></div>');

		var info = g.parents('.product-package__info-item');
		if (info.length && info.is(':hidden')) {
			info.show();
		} else {
			info = false;
		};

		var swiper = new Swiper(g.find('.swiper-container')[0],{
			loop: true,
			speed: 400,
			autoHeight: true,
			autoplayDisableOnInteraction: true,
			slidesPerView: 4,
			navigation: {
				prevEl: g.find('.product__related-slider-nav._prev')[0],
				nextEl: g.find('.product__related-slider-nav._next')[0],
				disabledClass: '_disabled',
				hiddenClass: '_hidden'
			},
			pagination: {
				el: g.find('.slider__controls-pagination')[0],
				clickable: true,
				bulletClass: 'slider__controls-pagination-item',
				bulletActiveClass: '_active'
			},
			breakpoints: {
				650: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				1000: {
					slidesPerView: 3
				}
			}
		});

		if (info != false && info.length) {
			info.hide();
		};
	};
});