$('.accordeon').each(function(){
	var ac = $(this),
		items = ac.find('> .accordeon__item');

	items.find('> .accordeon__heading').append(ard);
	items.each(function(){
		var item = $(this);

		item.find('> .accordeon__heading').on('click',function(){
			var h = $(this);
			if (!h.hasClass('_active')) {
				items.find('> .accordeon__heading._active').trigger('click');
				h.addClass('_active');
				item.find('> .accordeon__body').stop().slideDown(200,function(){
					if (D.scrollTop() > item.offset().top) {
					Z.scrollBodyTo(item.offset().top - 15);
					}
					
				});

			} else {
				h.removeClass('_active');
				item.find('> .accordeon__body').stop().slideUp(200);
			};
		});
	});

	items.find('> .accordeon__heading._active').parent().find('> .accordeon__body').show();
});